import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import "styles/pages/page-404.scss";
import { Link } from "gatsby";

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="404" />
      <section className="section-non">
        <div className="container">
          <div className="section-non__img-container">
            <img
              src={require("assets/images/img_404.png").default}
              alt=""
              className="img-fluid section-non__img"
            />
          </div>
          <div className="section-non__text">
            <span>Don’t worry, you didn’t break the Internet</span> but we can’t
            find what you’re looking for.
          </div>

          <Link to="/" className="btn section-non__btn">
            Main Page
          </Link>
        </div>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
